html,
body {
  font-size: 14pt !important;
  letter-spacing: 0.2pt;
  line-height: 22pt;
  height: 100%;
}
body {
  background: #ffffff;
  font-family: "SourceSansPro";
  color: #000000;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
body a {
  text-decoration: underline;
  cursor: pointer !important;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.hide {
  display: none !important;
}
.txt-white {
  color: #fff;
}
.text-small {
  font-size: 0.857rem;
}
.text-medium {
  font-size: 1.125rem;
}
.text-large {
  font-size: 1.275rem;
}
.text-semi-bold {
  font-family: "SourceSansProSemiBold";
}
.text-bold {
  font-family: "SourceSansProBold";
}
.text-header {
  font-family: "LatoBold";
}
.text-white {
  color: #ffffff;
}
.text-lightblue {
  color: #cad0d6;
}
.text-blue {
  color: #0d63b0;
}
.text-blue2 {
  color: #1776cb;
}
a {
  color: #2973b0 !important;
  text-decoration-color: #548dbf !important;
}
a.no-underline {
  text-decoration: none;
}
button {
  cursor: pointer;
}
*:focus {
  outline-color: #92c8f7;
}
a:hover,
a:focus {
  text-decoration: none !important;
  color: #3b99e7 !important;
}
a:focus {
  outline: none !important;
}
a.link-lightblue {
  color: #cad0d6 !important;
}
a.link-white {
  color: #ffffff !important;
}
a.link-white:hover,
a.link-white:focus,
a.header1-text:hover,
a.header1-text:focus {
  color: #fabe4f !important;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.clear {
  clear: both;
  overflow: hidden;
}
.v-middle {
  vertical-align: middle;
}
.align-v-top {
  display: flex;
  align-items: flex-start;
}
.align-v-middle {
  display: flex;
  align-items: center;
}
.i-block {
  display: inline-block;
}
.relative {
  position: relative;
}

.wrapper-main {
  width: 100%;
  min-height: 100%;
  position: relative;
  margin: 0 auto;
}



/* Header */
.header1 {
  background-color: #033769;
  border-bottom: #012e57 solid 1px;
  padding: 0.75rem 2.2rem;
}
.ng-logo-img {
  vertical-align: middle;
  width: 5rem !important;
  height: auto;
  margin-right: 0.572rem;
}
.header1-text {
  color: #ffffff !important;
  font-size: 1rem;
}
.nj-logo.large {
  display: flex;
  align-items: center;
}
.nj-logo.large .ng-logo-img {
  width: 59px;
  height: 59px;
}
.nj-logo.large .header1-text {
  font-size: 1.1rem;
}

.headerSection {
  background-color: #001f3b;
  border-bottom: #639ed1 solid 1px;
  padding: 0.8rem 2.2rem;
  position: relative;
}

.header2 {
  // background-color: #001f3b;
  // border-bottom: #639ed1 solid 1px;
  // padding: 0.8rem 2.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-innerpage {
  background-color: #001f3b;
  border-bottom: #639ed1 solid 1px;
  padding: 1.1rem 12.5rem;
}
.logo-img {
  vertical-align: middle;
  width: 280px ;
  height: auto;
  margin-right: 0.572rem;
}
.btn-blue {
  background-color: #1776cb;
  color: #f9fcfe;
  padding: 0.75rem 1.2rem;
  border: none;
  border-radius: 5px;
  font-family: "SourceSansProSemiBold";
  font-size: 1rem;
}
.text-register {
  font-family: "SourceSansProSemiBold";
}
.btn-blue.large {
  padding: 0.78rem 2.3rem;
  font-size: 1.15rem;
}
.p-pull-right {
  float: right;
}

.mobileLogin,
.mobileView {
  // visibility: hidden;
  display: none !important;
}
.desktopLogin {
  // visibility: visible;
  display: inline-block !important;
}

/*Footer*/
.footer {
  background-color: #f2f5f8;
  padding: 1.786rem 2.2rem 1.072rem 2.2rem;
}
.footer-inner {
  // padding: 1.786rem 12.5rem 1.072rem 12.5rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}
.footer-section1 {
  border-bottom: #dfe3e8 solid 1px;
  margin-bottom: 1.429rem;
  padding: 0 25rem;
  padding-bottom: 2.143rem;
  position: relative;
}
.footer-section1 div[class^="p-col"]:nth-of-type(1) {
  width: 60%;
}
.footer-section1 div[class^="p-col"]:nth-of-type(2) {
  width: 40%;
}
.footer-section1 div[class^="p-col"]:nth-of-type(3) {
  width: 30%;
}
.footer-section1 div[class^="p-col"]:nth-of-type(4) {
  width: 30%;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-footer a {
  text-decoration: none;
  font-size: 0.857rem;
  margin-right: 2.5rem;
}
.menu-footer a:hover {
  text-decoration: underline !important;
}



/* Buttons */

.btn-stroke {
  background-color: transparent;
  border: solid 1px #1776cb;
}
.stroke-blue {
  color: #1776cb;
}

/* Styles Inner Page */
.container-apply-tab {
  background-color: #ffffff;
}
.container-apply-tab-header {
  background-color: #f2f5f8;
  padding: 1.1rem 12.5rem;
}



.tab-content {
  background-color: #ffffff;
  padding: 1.1rem 12.5rem 2rem 12.5rem;
  overflow: hidden;
  // width: 50%;
  box-sizing: content-box !important;
  text-align: justify;
}
.page-main-header {
  font-family: "LatoBold";
  font-size: 1.5rem;
  color: #1776cb;
  margin-bottom: 1.7rem;
}

p.text-large {
  line-height: 2rem;
}
.btn-skip {
  background-color: #ccc;
  color: #333;
  transition: all 200ms cubic-bezier(0.26, 0.86, 0.44, 0.985);
}
.btn-skip:hover {
  background-color: #1776cb;
  color: #f9fcfe;
}
.btn-blue.btn-icon-left {
  padding-left: 2rem;
}
.btn-blue.btn-icon-left i,
.btn-blue.btn-icon-right i {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}
.btn-blue.btn-icon-left i {
  left: 0.6rem;
}

@media screen and (min-width: 1360px) and (max-width: 1680px) {
  html,
  body {
    font-size: 12pt !important;
    line-height: 18pt !important;
  }
  
  .header1 {
    padding: 0.75rem 2rem;
  }
 
  .logo-img {
    width: 300px !important;
  }
  
  .footer {
    padding: 1.786rem 2rem 1.072rem 2rem;
  }
  .footer-inner {
    padding: 1.786rem 2rem 1.072rem 2rem;
  }
  
  .header-innerpage {
    padding: 1.1rem 2rem;
  }
  .container-apply-tab-header {
    padding: 1.1rem 2rem;
  }
  .tab-content {
    // width: 60%;
    padding: 1.1rem 2rem 2rem 2rem;
  }
}

@media screen and (max-width: 1680px) {
  html,
  body {
    font-size: 12pt !important;
    line-height: 18pt;
  }

  
  .header1 {
    padding: 0.75rem 2rem;
  }

  .logo-img {
    width: 380px !important;
	left: -8rem !important;
  }
  
 
  .footer {
    padding: 1.786rem 2rem 1.072rem 2rem;
  }
  .footer-inner {
    padding: 1.786rem 2rem 1.072rem 2rem;
  }
  
  .header-innerpage {
    padding: 1.1rem 2rem !important;
  }
  .container-apply-tab-header {
    padding: 1.1rem 2rem;
  }
  .tab-content {
    // width: 60%;
    padding: 1.1rem 2rem 2rem 2rem;
  }

}



@media screen and (min-width: 375px) and (max-width: 767px) {
  html,
  body {
    font-size: 12pt !important;
    line-height: 18pt !important;
  }

  .header1 {
    padding: 2rem 1.5rem 0.75rem 1.5rem;
  }
  .header1-text {
    font-size: 0.85rem;
  }
  .header1-text.line2 {
    display: block;
    margin-top: -0.5rem;
  }
  .header1-text.line3 {
    margin-left: 3rem;
  }
  .nj-logo .header1-text span {
    display: block;
  }
  .ng-logo-img {
    width: 90px;
    margin-bottom: 0.7rem;
    float: left;
  }

  .header2 .btn-blue {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 0.95rem;
    margin-right: 0 !important;
    margin-left: 1rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .logo-img {
    width: 300px;
  }
 
  .container-important-links {
    padding: 1rem 1.5rem;
  }
  .container-important-links h3 {
    margin: 1rem 0 1.5rem 0;
    font-size: 1.1rem;
  }
  .widget-type2 p {
    font-size: 1rem;
  }
  .btn-circle.btn-xl {
    width: 5.8rem;
    height: 5.8rem;
    border-radius: 2.9rem;
  }
  .widget-type3 {
    margin-bottom: 1.3rem;
  }
  .widget-type3 .btn-circle img {
    max-width: 50%;
  }
  .widget-type3 h5 {
    font-size: 0.95rem;
    margin: 0.5rem 0 0.5rem 0;
  }
  .container-call {
    padding: 1rem;
  }
  .footer-section1 {
    padding: 0;
    border-bottom: 1px solid #c4cad1;
  }
  .footer-section1 div[class^="p-col"]:nth-of-type(1),
  .footer-section1 div[class^="p-col"]:nth-of-type(2),
  .footer-section1 div[class^="p-col"]:nth-of-type(3),
  .footer-section1 div[class^="p-col"]:nth-of-type(4) {
    width: 100%;
    border-bottom: #c4cad1 solid 1px;
    padding-left: 0;
  }
  .footer-section1 div[class^="p-col"]:nth-of-type(4) {
    padding-bottom: 10rem;
  }
  
  
  .footer {
    padding: 1.786rem 1.5rem 1.072rem 1.5rem;
  }
  .footer-inner {
    position: relative;
  }
  .footer-section1 {
    border-bottom: #c4cad1 solid 1px;
  }
  .menu-footer {
    border-bottom: #c4cad1 solid 1px;
    padding-bottom: 0.4rem;
  }
  .menu-footer a {
    display: block;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .footer-powered {
    margin-top: 1.5rem;
  }
  .footer-copyright {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  /*** Inner Pages ***/
  .header-innerpage {
    padding: 0;
  }
  .header-innerpage-1 {
    padding: 1rem 1.5rem;
  }
  .header-innerpage-2 {
    background-color: #033769;
    padding: 0 1.5rem 1rem 1.5rem;
  }
  .header-innerpage-2 .nj-logo.large {
    align-items: start;
  }
  .header-innerpage-2 .nj-logo.large .ng-logo-img {
    width: 40px;
    height: auto;
  }
  .header-innerpage-2 .nj-logo.large .header1-text.text-line1,
  .header-innerpage-2 .nj-logo.large .text-line2,
  .header-innerpage-2 .nj-logo.large .text-line3 {
    font-size: 0.85rem;
    line-height: normal;
  }
  .header-innerpage-2 .nj-logo.large .header1-text.text-line1 span {
    display: none;
  }
  .header-innerpage-2 .nj-logo.large .text-line3 {
    display: inline-block;
    margin-top: 0.8rem;
  }

  
  .d-none {
    display: none;
  }

  .tab-content {
    padding: 1.1rem 1.3rem 2rem 1.3rem;
    width: auto;
  }
  .page-main-header {
    font-size: 1.3rem;
    margin-bottom: 0.3rem;
    margin-top: 0.5rem;
  }
  
  
  .tab-content p {
    margin: 0.5rem 0;
    font-size: 1.1rem;
    text-align: justify;
    line-height: 1.5rem;
  }
 
  .tab-content .text-large {
    font-size: 1.05rem;
  }
  .btn-blue.large {
    padding: 0.7rem 2.1rem;
    font-size: 1.1rem;
  }
}


@media screen and (min-width: 768px) {
  .header-innerpage-2 .nj-logo.large .text-line3 {
    display: none;
  } 
}

@media screen and (max-width: 768px) {

  .mobileLogin {
    display: inline-block !important;
  }

  .desktopLogin {
    display: none !important;
  }
  .nj-logo {
    border: none !important;
  }

  .mobileView {
    display: block !important;
  }
}


@media screen and (min-width: 768px) and (max-width: 959px) {
  .header2 {
    padding: 1rem 0 !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 1680px) {
  html,
  body {
    font-size: 12pt !important;
    line-height: 18pt;
  }
}

@media screen and (max-width: 1359px) {
  .tab-content {
    width: auto !important;
  }
}

@media screen and (max-width: 1023px) {

  .logo-img {
    width: 380px !important;
  }
}





@media screen and (min-width: 768px) and (max-width: 900px) {
  .headerSection {
    padding: 0.8rem 1rem !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .logo-img {
    width: 120px !important;
  }

  .smallCaps {
    line-height: 1rem;
  }

  .ng-logo-img {
    width: 3rem !important;
  }

  .headerSection {
    padding: 0 0.5rem !important;
  }

  .header2 {
    padding: 0.5rem !important;
  }

  .header-lang-text {
    right: 1rem !important;
    // font-size: 0.6rem !important;
  }
}
